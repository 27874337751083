import {faSignOutAlt,faHouse, faHeadphones, faMicrophone, faDashboard, faPeopleGroup, faTh, faUsers, faCog, faFileAlt, faLifeRing } from '@fortawesome/free-solid-svg-icons';

export const navData = [
    {
        routeLink: '/dashboard',
        icon: faDashboard,
        label: 'Dashboard'
    },
    {
        routeLink: '/dashboard/administrator',
        icon: faPeopleGroup,
        label: 'Administrator'
    },
    {
        routeLink: 'accomodations',
        icon: faTh,
        label: 'Accomodations'
    }, 
    {
        routeLink: 'bookings',
        icon: faUsers,
        label: 'Bookings'
    },
    {
        routeLink: 'manage',
        icon: faCog,
        label: 'Manage'
    },
    {
        routeLink: 'reports',
        icon: faFileAlt,
        label: 'Reports'
    },
    {
        routeLink: 'support',
        icon: faLifeRing,
        label: 'Support'
    },
    {
        routeLink: '',
        icon: faSignOutAlt,
        label: 'Logout'
    }
];
