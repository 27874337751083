import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Modules/Auth/AuthModule/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { SignUpComponent } from './Modules/Auth/AuthModule/sign-up/sign-up.component';
import { HttpRequestInterceptor } from './Infrastructure/Interceptors/http-request-interceptor';
import { LoaderComponent } from './Infrastructure/loader/loader/loader.component';
import { LoaderInterceptor } from './Infrastructure/Interceptors/loader-interceptor';
import { FeatureModule } from "./Modules/feature/feature/feature.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    LoginComponent,
    LoaderComponent
  ],
  providers: [HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FeatureModule,
    NgbModule,
    RouterModule
  ]
})
export class AppModule { }
