import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICustomerAccount } from 'src/app/Core/Models/CustomerAccount';
import { AccountService } from 'src/app/Core/Services/Account/account.service';
import { CustomerAccountService } from 'src/app/Core/Services/Account/customer-account/customer-account.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  //userProfile : FormGroup;

  userProfile= new FormGroup({
    firstname: new FormControl('',Validators.required),
    lastname: new FormControl('',Validators.required),
    email: new FormControl('',Validators.required),
    phoneNumber: new FormControl('',Validators.required),
    password: new FormControl('',Validators.required),
    confirmPassword: new FormControl('',Validators.required)
  })
  constructor(private formBuilder: FormBuilder, private customerAccountService: CustomerAccountService, private route: Router) { }

  ngOnInit(): void {
   
  }

  onSubmit(){
    if(this.userProfile.valid){
      const profile= this.userProfile.value;
      const customerAccountData: ICustomerAccount = {
        FirstName: profile.firstname,
        LastName: profile.lastname,
        Email: profile.email,
        PhoneNumber: profile.phoneNumber,
        password: profile.password,
      };
      this.customerAccountService.createCustomer(customerAccountData).subscribe((result)=>{    
      if(result != null){
       this.userProfile.reset();
        this.route.navigateByUrl['']
      } 
      })
    }
  }
}
