import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { navData } from '../navData';
import { AuthenticationService } from 'src/app/Core/Services/authentiction/authentication.service';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/Core/Utility/utility.service';
import { MyAccountComponent } from 'src/app/Modules/Admin/admin/administrators/my-account/my-account.component';


// interface SideNavToggle {
//   screenWidth: number;
//   collapsed: boolean;
// }
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent {

  private activeModal: NgbActiveModal;
  private modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;
  // @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  // collapsed = false;
  // screenWidth = 0;
  navData = navData;
  FullName: string;
  Role: string[];


  constructor(public authService: AuthenticationService, private modal: NgbModal,
    private utilityService: UtilityService) {
    this.modalOptions = {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true
    };
  }

  ngOnInit(): void {

  }



  // toggleCollapse(): void {
  //   this.collapsed = !this.collapsed;
  //   this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  // }

  // closeSideNav(): void {
  //   this.collapsed = false;
  //   this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  // }

  goBack() {
    window.history.back();
  }


  toggleNav() {
    var sidenav = document.getElementById('mySidenav');
    sidenav.classList.toggle("show");
  }

  editMyAccount() {
    this.modalRef = this.modal.open(MyAccountComponent, this.modalOptions);
  }

  Logout() {
    this.authService.logout();
  }
}
