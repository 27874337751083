import { environment } from 'src/environments/environment';

export class CurrentEnvironment {

  public ConnectedEnvirnmentName: any;

  constructor() { }

  setApiURL() {

    var url = window.location.hostname;
    if (url.toLowerCase().includes(environment.HostApp.Environments.Local.toLowerCase())) {
      environment.apiUrl = environment.HostApp.Environments.Local;
    } else if (url.toLowerCase().includes(environment.HostApp.Environments.demo.toLowerCase())) {
      environment.apiUrl = environment.HostApp.Environments.demo;
      console.log("environment.apiUrl", environment.apiUrl)
    }
  }

  public getConnection() {

    this.setApiURL();

    if (environment.apiUrl == environment.HostApp.Environments.Local) {
      this.ConnectedEnvirnmentName = environment.HostApp.Environments.Local;
      return environment.HostApp.APIURL.Local;
    }

    if (environment.apiUrl == environment.HostApp.Environments.demo) {
      this.ConnectedEnvirnmentName = environment.HostApp.Environments.demo;
      return environment.HostApp.APIURL.demo;
    }
    return "";
  }
}
