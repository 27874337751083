<div>
    <div class="modal-header">
        <h5 class="modal-title">My Account</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"
            (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="container">
            <form [formGroup]="userProfile" (ngSubmit)="handleSubmit()">
                <div class="row">
                    <div class="col-md-6">
                        <label for="Firstname" class="form-label fw-bold text-secondary">Firstname</label>
                        <input type="text" class="form-control" formControlName="Firstname"
                            placeholder="Please enter firstname" />
                        <div *ngIf="userProfile.controls['Firstname'].invalid && userProfile.controls['Firstname'].touched"
                            class="text-danger">
                            Firstname is required
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="Lastname" class="form-label fw-bold text-secondary">Lastname</label>
                        <input type="text" class="form-control" formControlName="Lastname"
                            placeholder="Please enter lastname" />
                        <div *ngIf="userProfile.controls['Lastname'].invalid && userProfile.controls['Lastname'].touched"
                            class="text-danger">
                            Lastname is required
                        </div>

                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="Email" class="form-label fw-bold text-secondary">Email</label>
                        <input type="email" class="form-control" formControlName="Email"
                            placeholder="Please enter email" />
                        <div *ngIf="userProfile.controls['Email'].invalid && userProfile.controls['Email'].touched"
                            class="text-danger">
                            <div *ngIf="userProfile.controls['Email'].errors?.['required']">Email is required</div>
                            <div *ngIf="userProfile.controls['Email'].errors?.['email']">Email is invalid</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="Role" class="form-label fw-bold text-secondary">Role</label>
                        <input type="text" class="form-control" formControlName="Role" readonly>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="Password" class="form-label fw-bold text-secondary">Password</label>
                        <input type="password" class="form-control" id="Password" formControlName="Password">
                        <div *ngIf="userProfile.controls['Password'].invalid && userProfile.controls['Password'].touched"
                            class="text-danger">
                            <div *ngIf="userProfile.controls['Password'].errors?.['minlength']">Password must be at
                                least 9 characters long</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="ConfirmPassword" class="form-label fw-bold text-secondary">Confirm Password</label>
                        <input type="password" class="form-control" id="ConfirmPassword"
                            formControlName="ConfirmPassword">
                        <div *ngIf="userProfile.controls['ConfirmPassword'].invalid && userProfile.controls['ConfirmPassword'].touched"
                            class="text-danger">
                            <div *ngIf="userProfile.controls['ConfirmPassword'].errors?.['minlength']">Confirm Password
                                must be at least 9 characters long</div>
                        </div>
                        <div *ngIf="userProfile.errors?.['passwordMisMatchError'] && userProfile.controls['ConfirmPassword'].touched"
                            class="text-danger mt-3">
                            Passwords must match
                        </div>
                    </div>
                </div>


                <!-- <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="Password" class="form-label fw-bold text-secondary">Password</label>
                        <input type="password" class="form-control" id="Password" formControlName="Password">
                        <div *ngIf="userProfile.controls['Password'].invalid && userProfile.controls['Password'].touched"
                            class="text-danger">
                            <div *ngIf="userProfile.controls['Password'].errors?.['required']">Password is required
                            </div>
                            <div *ngIf="userProfile.controls['Password'].errors?.['minlength']">Password must be at
                                least 9
                                characters long</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="ConfirmPassword" class="form-label fw-bold text-secondary">Confirm
                            Password</label>
                        <input type="password" class="form-control" id="Password" formControlName="ConfirmPassword">
                        <div *ngIf="userProfile.controls['ConfirmPassword'].invalid && userProfile.controls['ConfirmPassword'].touched"
                            class="text-danger">
                            <div *ngIf="userProfile.controls['ConfirmPassword'].errors?.['required']">Confirm Password
                                is
                                required</div>
                            <div *ngIf="userProfile.controls['ConfirmPassword'].errors?.['minlength']">Confirm Password
                                must
                                be at least 9 characters long</div>
                        </div>
                        <div *ngIf="userProfile.errors?.['passwordMisMatchError'] && userProfile.controls['ConfirmPassword'].touched"
                            class="text-danger mt-3">
                            Passwords must match
                        </div>
                    </div>
                </div> -->

                <div class="row mt-3">
                    <div class="col-md-12 text-center d-flex justify-content-start">
                        <button type="submit" class="btn btn-primary">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
