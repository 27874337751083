import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/Modules/Auth/AuthModule/login/login.component';
import { AdminService } from '../../../../Core/Services/Admin/admin.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  private activeModal: NgbActiveModal;
  private modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;
  accommodationTypes: any;
  constructor(private router: Router, private modal: NgbModal, private adminService: AdminService) {
    this.modalOptions = {
      backdrop: 'static',
      size: 'md',
      fullscreen: 'lg',
      keyboard: false,
      centered: true
    };
  }
  ngOnInit(): void {
    this.getAccommodationTypes();
  }

  getAccommodationTypes() {
    this.adminService.getAccommodationTypes().subscribe((result: any) => {
      this.accommodationTypes = result;
    })
  }

  goToAccommodation(name?: string): void {
    // Find the matching accommodation type by name
    const matchedType = this.accommodationTypes.find(type => type.accomodatioN_TYPE.toLowerCase() === name?.toLowerCase());

    if (matchedType) {
      // Navigate with the found CATEGORY_ID
      this.router.navigate(['categories'], {
        queryParams: { CATEGORY_ID: matchedType.accomodatioN_TYPEID }
      });
    } else {
      console.warn('Accommodation type not found for the given name:', name);
    }
  }

  async loadLoginComponent() {
    this.modalRef = this.modal.open(LoginComponent, this.modalOptions)
  }
}
