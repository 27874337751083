<div class="container">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="userProfile" (ngSubmit)="onSubmit()">
        <!-- First Name -->
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="firstname">First Name:</label>
              <input type="text" id="firstname" class="form-control" formControlName="firstname">
            </div>
          </div>
        </div>

        <!-- Last Name -->
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="lastname">Last Name:</label>
              <input type="text" id="lastname" class="form-control" formControlName="lastname">
            </div>
          </div>
        </div>

        <!-- Email -->
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="email">Email:</label>
              <input type="email" id="email" class="form-control" formControlName="email">
            </div>
          </div>
        </div>

        <!-- Phone -->
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="phone">Phone:</label>
              <input type="text" id="phoneNumber" class="form-control" formControlName="phoneNumber">
            </div>
          </div>
        </div>

        <!-- Password -->
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" id="password" class="form-control" formControlName="password">
            </div>
          </div>
        </div>

        <!-- Confirm Password -->
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="confirmPassword">Confirm Password:</label>
              <input type="password" id="confirmPassword" class="form-control" formControlName="confirmPassword">
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-4">
            <button type="submit" class="btn btn-primary">Sign Up</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
