import { Injectable } from '@angular/core';
import { ToastrType } from '../Constants/const-var';
import { ToastrService } from 'ngx-toastr';
import * as _ from "lodash";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  isDesc: boolean = false;
  constructor(private toastrService: ToastrService) { }
  /**
   * Check if a value is null or undefined.
   * @param value The value to check.
   * @returns True if the value is null or undefined, false otherwise.
   */
  isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }
  isNullOrEmpty(value: string | null | undefined): boolean {
    return value == null || value.trim() === '';
  }
  // Utility function to format the date as yyyy-MM-dd
  formatDate(date: any): string | null {
    const d = new Date(date);
    if (!isNaN(d.getTime())) {
      return d.toISOString().split('T')[0]; // Returns the date in yyyy-MM-dd format
    }
    return null; // Return null if the date is invalid
  }
  /**
   * Display a toast notification based on the type and message.
   * @param type The type of toastr notification.
   * @param message The message to display in the toastr.
   * @returns True if the toastr was shown, false otherwise.
   */
  toastr(type: ToastrType, message: string): boolean {
    this.toastrService.clear();
    if (this.isNullOrEmpty(message) || message.includes("User information is missing")) {
      console.log(message);
      return false;
    }

    switch (type) {
      case ToastrType.Success:
        this.toastrService.success(message);
        break;
      case ToastrType.Warning:
        this.toastrService.warning(message);
        break;
      case ToastrType.Info:
        this.toastrService.info(message);
        break;
      case ToastrType.Error:
        this.toastrService.error(message);
        break;
    }

    return true;
  }

  //region sorting method (generic, added by solat)
  sortList(property, itemList, event) {
    var self = this;

    //reset the sorting icons
    this.reset_SortIcons();

    //change the direction
    this.isDesc = !this.isDesc;

    if (!!itemList.reduce(function (a, b) { return (a[property] === b[property]) ? a : NaN; }, 0)) {

      var orderClass = (this.isDesc) ? 'headerSortDown' : 'headerSortUp';
      event.target.classList.add(orderClass)
      return itemList;
    }

    //sort array in respective order
    var theItem; var i = 0; var value; var data = itemList; var allNumbers = true; var allDates = true; var allBoolean = true;
    var allEmpty = true;

    //check if this property values are all numeric or not..
    try {
      for (i = 0; i < itemList.length; i++) {
        //current item
        theItem = itemList[i];
        value = theItem[property];
        if (value != "" && value != null && value != undefined) {

          allEmpty = false;

          //check if boolean
          if (value != true && value != false && typeof (value) != "boolean") {
            allBoolean = false
          }
          else {
            //value is a boolean
            allNumbers = false;
            allDates = false;
          }

          if (!allBoolean) {
            value = value.toString();
            //check if number
            if (isNaN(value)) {

              allNumbers = false;

              //string value
              if (allBoolean == false) {
                if (!self.isValidDate(value)) {
                  allDates = false;
                }
              }
            }
          }
        }
      }
      if (allEmpty) {
        //console.log("All empty list, do  not sort..");
        data = itemList;
      }
      else {
        if (allNumbers) {
          //if all values in this column are numeric...
          data = itemList.sort(function (a, b) {
            function getValue(v) {

              if (v == null || v == undefined)
                v = "";

              v = v.toString();
              if (!v.includes(".") && !v.includes(",")) {
                return v;
              }

              if (v.includes(".") && v.includes(",")) {
                return parseFloat(v).toFixed(0).replace(',', '');
              }
              else if (v.includes(".") && !v.includes(",")) {
                return parseFloat(v).toFixed(0);
              }
              else if (!v.includes(".") && v.includes(",")) {
                return parseFloat(v).toFixed(0).replace(',', '');
              }
              else {
                return parseFloat(v).toFixed(0).replace(/,/g, '').replace(/./g, '');
              }
            }
            var number1 = getValue(a[property]);
            var number2 = getValue(b[property]);
            if (self.isDesc) {
              //descending order
              if (number1 == number2) return 0;
              else if (number2 == null) return -1;
              else if (number1 == null) return 1;
              else return number2 - number1;
            }
            else {
              //ascending order
              if (number1 == number2) return 0;
              else if (number1 == null) return -1;
              else if (number2 == null) return 1;
              else return number1 - number2;
            }
          });
        }
        else {
          if (allBoolean) {
            data = itemList.sort(
              function (a, b) {
                let bool1 = a[property];
                let bool2 = b[property];

                if (self.isDesc) {
                  //descending order
                  if (bool1 == bool2) return 0;
                  else return ((bool2 && !bool1) ? 1 : -1);
                }
                else {
                  //ascending order
                  if (bool1 == bool2) return 0;
                  else return ((!bool2 && bool1) ? 1 : -1);
                }
              }
            );
          }
          else {
            //set empty string
            for (i = 0; i < itemList.length; i++) {

              //current item
              theItem = itemList[i];
              value = theItem[property];
              if (value == null || value == undefined) {
                theItem[property] = "";
              }
              if (value != null && value != undefined && value != "") {
                allEmpty = false;
              }
            }

            if (allDates) {
              data = itemList.sort(
                function (a, b) {
                  let date1 = a[property];
                  let date2 = b[property];
                  var result;
                  if (self.isDesc) {
                    if (date1 == "" && date2 == "") {
                      result = 0;
                    }

                    if (date1 == "") {
                      result = 1;
                    }
                    else if (date2 == "") {
                      result = -1;
                    }
                    else {
                      date1 = new Date(date1);
                      date2 = new Date(date2);

                      if (date1.getTime() === date2.getTime())
                        result = 0;
                      else
                        return (date2 > date1) ? 1 : -1;
                    }
                  }
                  else {
                    if (date1 == "" && date2 == "") {
                      result = 0;
                    }

                    if (date1 == "") {
                      result = -1;
                    }
                    else if (date2 == "") {
                      result = 1;
                    }
                    else {
                      date1 = new Date(date1);
                      date2 = new Date(date2);

                      if (date1.getTime() === date2.getTime())
                        result = 0;
                      else
                        result = (date2 < date1) ? 1 : -1;
                    }
                  }
                  return result;
                }
              );
            }
            else {
              data = _.orderBy(itemList, [property], [(this.isDesc) ? 'desc' : 'asc']);
            }
          }
        }
      }

      //change the sorting icon
      var orderClass = (this.isDesc) ? 'headerSortDown' : 'headerSortUp';
      event.target.classList.add(orderClass)

      //return the sorted array
      return data;
    }
    catch (e) {
      console.log(e);
    }
  }

  reset_SortIcons() {
    $(".sortable-table-with-freezeHeader th").each(function () {
      $(this).removeClass("headerSortUp");
      $(this).removeClass("headerSortDown");
      $(this).addClass("header");
    });
    this.removeSortingIcons();
  }

  removeSortingIcons() {
    $("table.sortable-table-with-freezeHeader th.no-sorting, table.sortable-table-with-freezeHeader th.no-sorting-icon, table.sortable-table-with-freezeHeader th.no-sort-icon").each(function () {
      $(this).removeClass("header");
    });
  }

  isValidDate(date) {
    var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/; //Declare Regex
    var dtArray = date.match(rxDatePattern); // is format OK?
    return (dtArray != null) ? true : false;
  }
}

