import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as forge from 'node-forge';
import { TokenStorageService } from 'src/app/Core/Services/Token/token-storage.service';
import { AuthenticationService } from 'src/app/Core/Services/authentiction/authentication.service';
import { ILogin } from 'src/app/Core/Services/authentiction/models/Login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  userProfile = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  })

  encryptedString: any;
  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDQZ0YVolLtPXXSowc6CopEXzGI
  /Tvri6hYT3KZ45G97DQn8ocydBQXSZfRR92MpiZHQn1zydpVBOCj7tUnSh1QoSN9
  aISG+tuLggYWdav6XlW2JAlduHkMbbyug9aoWIyaZjXXzyV+0e3hjwQhfTeQj9DL
  uGssWNX3YuYgf/e5LQIDAQAB
  -----END PUBLIC KEY-----`;
  Username1: string;
  Password1: string;

  encryptWithPublicKey(valueToEncrypt: string): string {
    const rsa = forge.pki.publicKeyFromPem(this.publicKey);
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
  }

  constructor(private authService: AuthenticationService,
    private router: Router,
    private tokenStorage: TokenStorageService, private modal: NgbModal) { }

  Login() {
    if (this.userProfile.valid) {
      const profile = this.userProfile.value;
      this.Username1=this.encryptWithPublicKey(profile.email);
      this.Password1=this.encryptWithPublicKey(profile.password);
      // this.encryptionService.loadPrivateKey().then(() => {
      //   // Private key loaded, you can perform encryption now
      // });
      const params: ILogin ={
        userName:this.Username1,
        Password:this.Password1,
        rememberMe:false,
      }
      // const userProfile: ILogin = {
      //   userName: profile.email,
      //   Password: profile.password,
      //   rememberMe: false
      // };
      this.authService.Login(params).subscribe((response: any) => {
        this.close();
        this.router.navigateByUrl('dashboard');
      })
    }
  }


  close() {
    this.modal.dismissAll();
  }

}
