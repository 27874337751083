<!-- <div class="container">
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="userProfile" (ngSubmit)="Login()">
                <div class="row">
                    <div class="col-md-10 form-group">
                        <label for="email">Email:</label>
                        <input type="email" id="email" class="form-control" formControlName="email">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 form-group">
                        <label for="password">Password:</label>
                        <input type="password" id="password" class="form-control" formControlName="password">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <button type="submit" class="btn btn-primary">Sign In</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->





<div>
    <div class="modal-header">
        <img src="assets/images/footer-logo.png" alt="Diyar Resorts">
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"
            (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="userProfile" (ngSubmit)="Login()">
                        <div class="row">
                            <div class="col-md-10 form-group">
                                <label for="email">Email:</label>
                                <input type="email" id="email" class="form-control" formControlName="email">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 form-group">
                                <label for="password">Password:</label>
                                <input type="password" id="password" class="form-control" formControlName="password">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-4">
                                <button type="submit" class="btn btn-primary">Sign In</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
