import { Injectable } from '@angular/core';
import { GenericService } from '../Generic/generic.service';
import { IAddLocation } from '../../Models/Locations/IAddLocation';
import { IAddFeatures } from '../../Models/Features/IAddFeatures';
import { IAddCategory } from '../../Models/Categories/IAddCategory';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  baseUrl = 'Manage/';

  constructor(private genericService: GenericService) { }



  public addLocation(obj: IAddLocation) {

    return this.genericService.PostData(this.baseUrl + 'AddLocation', obj)
  }

  public getLocations() {

    return this.genericService.GetData(this.baseUrl + 'GetLocations')
  }
  
  public getAccommodationTypes() {
    return this.genericService.GetData(this.baseUrl + 'GetAccommodationTypes')
  }

  public getRoomsByBooking(bookingId: any) {
    return this.genericService.GetData(this.baseUrl + 'GetRoomsByBooking?bookingId=' + bookingId);
  }

  public updateLocation(obj: IAddLocation) {

    return this.genericService.PostData(this.baseUrl + 'UpdateLocation', obj)
  }
  public deleteLocation(ID: number) {

    return this.genericService.DeleteData(this.baseUrl + 'DeleteLocation/' + ID)
  }

  public GetLocationsByCategory(categoryId: number) {

    return this.genericService.GetDataById(this.baseUrl + 'GetLocationsByCategory/' + categoryId)
  }

  public addCategory(obj: IAddCategory) {
    const formData = new FormData();

    for (const propertyName in obj) {
      if (!obj.hasOwnProperty(propertyName) || obj[propertyName] === undefined || obj[propertyName] === null) {
        continue;
      }
      if (Array.isArray(obj[propertyName])) {
        console.log(`Property ${propertyName} is an array`, obj[propertyName]);
        obj[propertyName].forEach((element: any) => {
          if (element instanceof File) {
            console.log(`Appending file:`, element);
            formData.append(propertyName, element, element.name);
          } else {
            console.error(`Element is not a File:`, element);
          }
        });
      } else {
        formData.append(propertyName, obj[propertyName].toString());
      }
    }

    return this.genericService.PostData(this.baseUrl + 'AddCategory', formData);
  }

  getCategoriesByAccommodationType(typeId: any) {
    return this.genericService.GetData(this.baseUrl + 'GetCategoriesByAccType?typeId=' + typeId);
  }

  getAccommodationTypesByLocation(LocationId: any) {
    return this.genericService.GetData(this.baseUrl + 'GetAccommodationTypesByLocation?LocationId=' + LocationId)
  }

  public getCategories() {
    return this.genericService.GetData(this.baseUrl + 'GetCategories')
  }
  public updateCategory(obj: any) {
    const formData = new FormData();

    for (const propertyName in obj) {
      if (!obj.hasOwnProperty(propertyName) || obj[propertyName] === undefined || obj[propertyName] === null) {
        continue;
      }
      if (Array.isArray(obj[propertyName])) {
        console.log(`Property ${propertyName} is an array`, obj[propertyName]);
        obj[propertyName].forEach((element: any) => {
          if (element instanceof File) {
            console.log(`Appending file:`, element);
            formData.append(propertyName, element, element.name);
          } else {
            console.error(`Element is not a File:`, element);
          }
        });
      } else {
        formData.append(propertyName, obj[propertyName].toString());
      }
    }
    return this.genericService.PostData(this.baseUrl + 'UpdateCategory', formData)
  }
  public deleteCategory(ID: number) {
    return this.genericService.DeleteData(this.baseUrl + 'DeleteCategory/' + ID)
  }

  public addFeatures(obj: IAddFeatures) {
    return this.genericService.PostData(this.baseUrl + 'AddFeatures', obj)
  }
  public getFeatures() {
    return this.genericService.GetData(this.baseUrl + 'GetFeatures')
  }
  public updateFeatures(obj: IAddFeatures) {
    return this.genericService.PostData(this.baseUrl + 'UpdateFeatures', obj)
  }
  public deleteFeature(ID: number) {
    return this.genericService.DeleteData(this.baseUrl + 'DeleteFeature/' + ID)
  }



}
