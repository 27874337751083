import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalItems } from 'src/app/Core/Constants/const-var';

export const authGuardGuard: CanActivateFn = (route, state) => {

  const token = localStorage.getItem(LocalItems.TOKEN_KEY);
  const router = inject(Router);
  if (token)
    return true;
  else {
    router.navigateByUrl('')
    return false;
  }
};
