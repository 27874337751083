import { Injectable } from '@angular/core';
import { LocalItems } from '../../Constants/const-var';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public SaveToken(token: string): void {
    window.localStorage.removeItem(LocalItems.TOKEN_KEY);
    window.localStorage.setItem(LocalItems.TOKEN_KEY, token);
  }
  public SaveRefreshToken(token: string): void {
    window.localStorage.removeItem(LocalItems.REFRESHTOKEN_KEY);
    window.localStorage.setItem(LocalItems.REFRESHTOKEN_KEY, token);
  }

  SignOut(): void {
    localStorage.removeItem(LocalItems.USER_INFO);
    localStorage.removeItem(LocalItems.TOKEN_KEY);
    localStorage.removeItem(LocalItems.REFRESHTOKEN_KEY);
  }
  public GetToken(): string | null {
    return window.localStorage.getItem(LocalItems.TOKEN_KEY);
  }
  public SaveUser(user: any): void {
    window.localStorage.removeItem(LocalItems.USER_INFO);
    window.localStorage.setItem(LocalItems.USER_INFO, JSON.stringify(user));
  }

  public GetUser(): string | null {
    return window.localStorage.getItem(LocalItems.USER_INFO);
  }
}
