import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/Core/Services/Account/account.service';
import { AdminService } from 'src/app/Core/Services/Admin/admin.service';
import { IAddAdministrator } from '../models/IAddAdministrator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { passwordMatch } from 'src/app/Core/Validators/ConfirmPassword';
import { AuthenticationService } from 'src/app/Core/Services/authentiction/authentication.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  userProfile = new FormGroup({
    Firstname: new FormControl('', [Validators.required]),
    Lastname: new FormControl('', [Validators.required]),
    Email: new FormControl('', [Validators.required, Validators.email]),
    Role: new FormControl(null),
    Branch: new FormControl(''),
    Password: new FormControl(''),
    ConfirmPassword: new FormControl('')
  }, { validators: passwordMatch('Password', 'ConfirmPassword') });

  addAdministrator: IAddAdministrator = {
    Firstname: '',
    Lastname: '',
    Email: '',
    Role: '',
    Password: ''
  }
  locations: any;
  adminUsers: any;

  constructor(private accountService: AccountService,
    private adminService: AdminService,
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.userProfile.patchValue({
      Firstname: this.authService.GetUser().firstName,
      Lastname: this.authService.GetUser().lastName,
      Email: this.authService.GetUser().userName,
      Role: this.authService.GetUser().userRole
    })
  }


  getLocations() {
    this.adminService.getLocations().subscribe((result: any) => {
      this.locations = result;
    })
  }

  handleSubmit() {
    this.userProfile.markAllAsTouched();
    if (this.userProfile.valid) {
      const profile = this.userProfile.value;
      this.addAdministrator.Firstname = profile.Firstname;
      this.addAdministrator.Lastname = profile.Lastname;
      this.addAdministrator.Email = profile.Email;
      this.addAdministrator.Password = profile.Password;

      this.accountService.updateAsync(this.addAdministrator).subscribe((response) => {
        this.CloseOnSuccess();
      })

    }
    else return

  }
  getAllAdmins() {
    this.accountService.getAllAdmins().subscribe((admins: any) => {
      this.adminUsers = admins;
    })
  }

  CloseOnSuccess() {
    this.activeModal.close("success")
  }
  close() {
    this.modal.dismissAll();
  }


}
