import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  public userName: string;
  public fullName: string;
  public userType: string;
  public userId: string;
  public id: number;
  public roles: string[];
  public firstName: string;
  public lastName: string;
  public isLoggedIn: Boolean = false;

  SetUser(userId: string,userName: string, fullName: string, userType: string, roles: string[],firstName,lastName) {
    this.userId = userId
    this.userName = userName;
    this.fullName = fullName;
    this.userType = userType;
    this.roles = roles;
    this.firstName= firstName;
    this.lastName = lastName;
    this.isLoggedIn = true;
  }

}
