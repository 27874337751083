import { AbstractControl } from "@angular/forms";

export function passwordMatch(password: string, ConfirmPassword: string){

    return  function(form: AbstractControl){
        const PasswordValue= form.get(password)?.value;
        const confirmPasswordValue= form.get(ConfirmPassword)?.value;
        if(PasswordValue===confirmPasswordValue){
            return null
        }
        return {passwordMisMatchError: true}
    }
}