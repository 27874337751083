import { Injectable } from '@angular/core';
import { GenericService } from '../../Generic/generic.service';
import { ICustomerAccount } from 'src/app/Core/Models/CustomerAccount';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountService {

  baseUrl = `CustomerAccount/`;
  constructor(public genericService: GenericService) { }

  createCustomer(customer:ICustomerAccount){
    return this.genericService.PostData(this.baseUrl +'RegisterCustomer',customer)
  }
  
}
