import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalItems, ToastrType } from 'src/app/Core/Constants/const-var';
import { TokenStorageService } from 'src/app/Core/Services/Token/token-storage.service';
import { UtilityService } from 'src/app/Core/Utility/utility.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private tokenStorageService: TokenStorageService,
        private utilityService: UtilityService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem(LocalItems.TOKEN_KEY);
        let clonedRequest = req;

        if (token) {
            clonedRequest = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            });
        }

        return next.handle(clonedRequest).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    // Handle successful response here if needed
                    const message = event.body?.message;
                    if (message) {
                        this.utilityService.toastr(ToastrType.Success, message);
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.tokenStorageService.SignOut();
                    this.router.navigate(['']);
                } else {
                    console.log("list of errors", error);

                    // Extracting and displaying error messages from the response
                    const errorMessages = error.error?.errorMessage;
                    if (Array.isArray(errorMessages)) {
                        errorMessages.forEach(err => {
                            this.utilityService.toastr(ToastrType.Error, err.innerMessage);
                        });
                    } else if (error.message) {

                        this.utilityService.toastr(ToastrType.Error, error.error.message);
                    }
                    else {
                        // Fallback to a generic error message if no specific messages are available
                        this.utilityService.toastr(ToastrType.Error, 'An unexpected error occurred.');
                    }
                }

                return throwError(() => new Error(error.message));
            })
        );
    }
}
