import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardGuard } from './Infrastructure/Guards/auth-guard.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./Modules/websiteView/website-module/website-module.module').then(m => m.WebsiteModuleModule) },
  { path: 'dashboard', canActivate: [authGuardGuard], loadChildren: () => import('./Modules/Admin/admin/admin.module').then(m => m.AdminModule) },
  { path: 'auth', canActivate: [authGuardGuard], loadChildren: () => import('./Modules/Auth/AuthModule/auth-module.module').then(m => m.AuthModuleModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
