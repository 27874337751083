import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBookingComponent } from 'src/app/Modules/Admin/admin/Bookings/confirm-booking/confirm-booking.component';
import { LoginComponent } from 'src/app/Modules/Auth/AuthModule/login/login.component';
import { AdminService } from '../../../../Core/Services/Admin/admin.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('login', { read: ViewContainerRef }) login !: ViewContainerRef;
  @ViewChild('signUp', { read: ViewContainerRef }) signUp !: ViewContainerRef;
  accommodationTypes: any;
  isMenuOpen: boolean = false;
  SignUpComponentRef: any;
  LoginComponentRef: any;
  IsAccountExist: boolean = false;
  private activeModal: NgbActiveModal;
  private modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;
  isNavbarActive: boolean = false;
  isDropdownOpen = false;
  locations: any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private route: Router,
    private modal: NgbModal,
    private adminService: AdminService,
    private router: Router) {
    this.modalOptions = {
      backdrop: 'static',
      size: 'md',
      fullscreen: 'lg',
      keyboard: false,
      centered: true
    };
  }
  ngOnInit(): void {
    this.getLocations();
    this.getAccommodationTypes();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isNavbarActive = window.scrollY > 50;
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;

    // const navbarToggler = document.querySelector('.navbar-toggler');
    // const navbar = document.querySelector('.navbar');
    // const navbarCollapse = document.querySelector('#navbarToggleExternalContent');

    // if (!this.isMenuOpen) {
    //   navbarToggler!.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_581_890" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="#1B3C25"/></mask><g mask="url(#mask0_581_890)"><path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#1B3C25"/></g></svg>`;
    //   navbar?.classList.add('bg-pastal-green', 'nav-expanded');
    //  .n/// (navbarCollapse as HTMLElement).classList.add('show');
    // } else {/nnk .j
    //   navbarToggler!.innerHTML = 'Menu';
    //   navbar?.classList.remove('bg-pastal-green', 'nav-expanded');
    //   (navbarCollapse as HTMLElement).classList.remove('show');
    // }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen; // Toggle state
  }

  getAccommodationTypes() {
    this.adminService.getAccommodationTypes().subscribe((result: any) => {
      this.accommodationTypes = result;
    })
  }

  goToAccommodation(name?: string): void {
    // Find the matching accommodation type by name
    const matchedType = this.accommodationTypes.find(type => type.accomodatioN_TYPE.toLowerCase() === name?.toLowerCase());

    if (matchedType) {
      // Navigate with the found CATEGORY_ID
      this.route.navigate(['categories'], {
        queryParams: { CATEGORY_ID: matchedType.accomodatioN_TYPEID }
      });
    } else {
      console.warn('Accommodation type not found for the given name:', name);
    }
  }

  getLocations() {
    this.adminService.getLocations().subscribe((result: any) => {
      this.locations = result;
    })
  }

  async openLocationTab(location: string) {
    const targetLocation = this.locations.find(loc => loc.loC_NAME.toLowerCase() === location.toLowerCase());
    if (targetLocation) {
      this.router.navigate(['locations'], {
        queryParams: { LOC_ID: targetLocation.id.toString() }
      });
      this.toggleMenu();
    }
  }

  async loadLoginComponent() {
    this.modalRef = this.modal.open(LoginComponent, this.modalOptions)
  }
  closeMenu() {
    this.isMenuOpen = false;
  }

  confirmBooking() {
    this.route.navigateByUrl("search");
    this.closeMenu();
    //this.modalRef = this.modal.open(ConfirmBookingComponent, this.modalOptions)
  }
  async loadSignUpComponent() {
    this.IsAccountExist = false;
    this.signUp.clear();
    const { SignUpComponent } = await import('../../../Auth/AuthModule/sign-up/sign-up.component');

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SignUpComponent);
    this.SignUpComponentRef = this.signUp.createComponent(componentFactory);
    this.LoginComponentRef ? this.LoginComponentRef.destroy() : null;
  }

}
