import { Injectable } from '@angular/core';
import { GenericService } from '../Generic/generic.service';
import { IAddAdministrator } from 'src/app/Modules/Admin/admin/administrators/models/IAddAdministrator';
import { AuthenticationService } from '../authentiction/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  baseUrl = 'Administrator/';

  constructor(private genericService: GenericService,private authService: AuthenticationService) {

  }

  getAllAdmins() {
    return this.genericService.GetData(this.baseUrl + 'GetAllAdmins')
  }

  createAsync(user: IAddAdministrator) {
    return this.genericService.PostData(this.baseUrl + 'RegisterEmployee', user)
  }

  updateAsync(user: IAddAdministrator) {
    return this.genericService.PostData(this.baseUrl + 'Update', user)
  }

  deleteAsync(Id: string) {
    return this.genericService.DeleteData(this.baseUrl + 'Delete/' + Id)
  }
}
