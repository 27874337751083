import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CurrentEnvironment } from '../../Constants/current-environment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
  private baseUrl: string;
  private currentEnvironment: CurrentEnvironment;

  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.currentEnvironment = new CurrentEnvironment();
    this.baseUrl = this.currentEnvironment.getConnection() + "api/";
  }

  // Subject to emit the response
  private responseSubject = new Subject<any>();

  //#region Get
  public GetData(url: string, completeURLProvided: boolean = false) {
    url = completeURLProvided ? url : this.baseUrl + url;
    return this.http.get<any>(url).pipe(
      tap(data => {
        this.responseSubject.next(data);
      }),
    );
  }
  //#endregion

  //#region  get without params
  public GetDataWOParam<T>(url: string) {
    return this.http.get<T>(this.baseUrl + url).pipe(
      tap(data => {
        this.responseSubject.next(data);
      }),
    );
  }
  //#endregion

  //#region  Post
  public PostData<T>(url: string, data: any) {
    return this.http.post<T>(this.baseUrl + url, data)
      .pipe(
        tap(data => {
          this.responseSubject.next(data)
        },
          error => { return error; },
          () => {
          }
        )

      );
  }
  //#endregion

  //#region Delete
  public DeleteData<T>(url: string) {
    return this.http.delete<T>(this.baseUrl + url).pipe(
      tap(responseData => {
        this.responseSubject.next(responseData);
      }),
    );
  }
  //#endregion


  //#region GetDataById
  public GetDataById<T>(url: string) {
    return this.http.get<T>(this.baseUrl + url).pipe(
      tap(responseData => {
        this.responseSubject.next(responseData);
      }),
    );
  }
  //#endregion  

  //#region Subject
  getResponseSubject() {
    return this.responseSubject.asObservable();
  }
  //#endregion
}
