// authentication.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ILogin } from './models/Login';
import { GenericService } from '../Generic/generic.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../Token/token-storage.service';
import { map } from 'rxjs';
import { Router } from '@angular/router';
import { User } from './models/user';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  baseUrl = 'Account/';
  private jwtHelper = new JwtHelperService();

  private isAuthenticated = false;
  private authSecretKey = 'Bearer Token';
  userToken: void;
  TokenArray: any;
  user: void;
  constructor(private genericService: GenericService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private userService: UserService) {
    //this.isAuthenticated = !!localStorage.getItem(this.authSecretKey);
  }

  Login(parms: ILogin) {
    return this.genericService.PostData<any>(this.baseUrl + 'Login', parms).pipe(
      map((user: any) => {
        this.tokenStorage.SaveToken(user.token);
        this.userToken = this.tokenStorage.SaveToken(user.token);
        this.tokenStorage.SaveRefreshToken(user.refreshToken);
        this.tokenStorage.SaveUser(user);
        this.TokenArray = JSON.parse(atob(user.token.split('.')[1]))
        this.user = this.userToken;
        return user;
      })
    )
  }


  GenerateRefreshToken(token: any) {

    return this.genericService.PostData(this.baseUrl + 'RefreshToken/', JSON.parse(token)).pipe(
      map((user: any) => {

        this.tokenStorage.SaveToken(user.token);
        this.tokenStorage.SaveRefreshToken(user.refreshToken);
        return user;
      })
    )
  }

  //#region Get User

  userInfo: User;
  public GetUser(): User {
    this.userInfo = {
      userId: '',
      userName: '',
      fullName: '',
      userType: '',
      userRole: [],
      firstName: '',
      lastName: '',
      isAuthenticated: false
    };

    var token = this.tokenStorage.GetToken();
    if (token != null) {
      const userData: any = this.jwtHelper.decodeToken(token);

      if (!userData) {
        console.log("Invalid Token");
        console.log("currentUser:",userData)
        return this.userInfo;
      }
      var myRole = userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var userId = userData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'];


      this.userService.SetUser(userId, userData.UserName, userData.FullName, userData.UserType, myRole, userData.FirstName, userData.LastName);
      this.userInfo.userId = this.userService.userId
      this.userInfo.userName = this.userService.userName;
      this.userInfo.fullName = this.userService.fullName;
      this.userInfo.userType = this.userService.userType
      this.userInfo.userRole = this.userService.roles;
      this.userInfo.firstName = this.userService.firstName;
      this.userInfo.lastName = this.userService.lastName;

      this.userInfo.isAuthenticated = true;
      return this.userInfo;

    }
    return this.userInfo;
  }

  //#endregion

  isAuthenticatedUser(): boolean {
    return this.isAuthenticated;
  }

  // Check if JWT token is expired
  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

  // Refresh JWT token
  refreshToken(refreshToken) {
    return this.genericService.PostData(this.baseUrl + 'RefreshToken', refreshToken);
  }



  logout(): void {
    this.tokenStorage.SignOut();
    this.isAuthenticated = false;
    this.router.navigate(['/']);
  }
}