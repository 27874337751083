
export const environment = {
    production: false,
    apiUrl: '',
    // ExpirationTimes: {
    //   PasswordResetLink: 60//mints
    // },
    // Login: {
    //   CaptchaAttempts: 5//captcha will display after 5 attempts 
    // },
    // Version: "0.0.0",
    HostApp: {
      APIURL: {
        Local: 'https://localhost:7271/',
        demo: 'https://api.diyarresorts.com/'
      },
      Environments: {
        Local: 'Local',
        demo: ''
      }
    }
  };
