export const URLS = {
    mainboard: {
        default: '/Mainboard',
        dashboard: '/dashboard'
    },
    auth: {
        login: '/login',
        forgetPassword: '/ForgetPassword'
    },
    DR: "/login"
}


export const LocalItems = {
    RememberMe: "rememberme",
    ResetPassword: "resetpassword",

    TOKEN_KEY: 'token',
    REFRESHTOKEN_KEY: 'refreshToken',
    USER_INFO: 'user',
}

export enum ToastrType {
    Success,
    Info,
    Error,
    Warning
}

